<template>
  <!-- <div class="content-field-phone"> -->
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <p class="text-title">Teléfono</p>
        <p class="text-information" v-if="sAreaCallingCodeOrigin">
          {{ formatPhoneGlobal(oContactInfo) }}
        </p>
        <p v-else class="text-data-empty">
          {{ emtpyDate }}
        </p>
      </v-col>
      <!-- <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <p class="text-title">Código de país</p>
        <div class="d-flex">
          <img class="mr-3" width="24" height="24" :src="getFlag()" />
          <p class="text-information">+ {{ sCountryCallingCodeOrigin }}</p>
        </div>
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <p class="text-title">Código de área</p>
        <p class="text-information" v-if="sAreaCallingCodeOrigin">
          {{ sAreaCallingCodeOrigin }}
        </p>
        <p v-else class="text-data-empty">
          {{ emtpyDate }}
        </p>
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <p class="text-title">Teléfono</p>
        <p class="text-information" v-if="sPhoneNumberOrigin">
          {{ sPhoneNumberOrigin }}
        </p>
        <p v-else class="text-data-empty">
          {{ emtpyDate }}
        </p>
      </v-col> -->
      <!-- <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <p class="text-title">Extensión</p>
        <p v-if="sPhoneExtensionOrigin" class="text-information">
          {{ sPhoneExtensionOrigin }}
        </p>
        <p v-else class="text-data-empty">
          {{ emtpyDate }}
        </p>
      </v-col> -->
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    bImportantDate: Boolean,
    sCountryCallingCodeOrigin: String,
    sAreaCallingCodeOrigin: String,
    sPhoneNumberOrigin: String,
    sPhoneExtensionOrigin: String,
  },
  data() {
    return {
      emtpyDate: "Sin información.",
      sFlagIcon: "",
      sFlagIconMXN: [require("@/assets/flag-mexico.png")],
      sFlagIconUSA: [require("@/assets/flag-united-states.png")],
      sCountryCallingCode: this.sCountryCallingCodeOrigin,
      sAreaCallingCode: this.sAreaCallingCodeOrigin,
      sPhoneNumber: this.sPhoneNumberOrigin,
      sPhoneExtension: this.sPhoneExtensionOrigin,
      aItemsCountryPhone: [
        {
          value: "52",
          text: "+52",
          icono: [require("@/assets/flag-mexico.png")],
        },
        {
          value: "1",
          text: "+1",
          icono: [require("@/assets/flag-united-states.png")],
        },
      ],
      oContactInfo: {}
    };
  },
  created() {
    this.getFlag();
    this.getContactoInfo();
  },
  methods: {
    getFlag() {
      switch (this.sCountryCallingCodeOrigin) {
        case "52":
          return [require("@/assets/flag-mexico.png")];
          break;
        case "1":
          return [require("@/assets/flag-united-states.png")];
          break;

        default:
          break;
      }
    },
    getContactoInfo() {
      this.oContactInfo = {
        sCountryCallingCode: this.sCountryCallingCodeOrigin,
        sAreaCallingCode: this.sAreaCallingCodeOrigin,
        sPhoneNumber: this.sPhoneNumberOrigin,
        sPhoneExtension: this.sPhoneExtensionOrigin
      }
    }
  },
  computed: {},
  watch: {},
};
</script>
<style scoped>
.text-title {
  margin-bottom: 2px;
  color: var(--primary-color-text-title-detail);
  font-family: "pop-Regular";
  opacity: 1;
  letter-spacing: 0px;
}

.text-information {
  margin-bottom: 5px;
  color: var(--primary-color-text);
  font-family: "pop-Regular";
}
</style>